/* eslint-disable no-undef */
import React from "react"
import data from "@components/pageCasosdeExito/data/dataPageMtg"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import dataOne from "@components/pageHome/data"
import IntroPageCustomer from "@components/pageCasosdeExito/components/introCustomer"
import ModulesCustomer from "@components/pageCasosdeExito/components/modulesCustomer"
import DescriptionCustomer from "@components/pageCasosdeExito/components/descriptionCustomer"

const StructureEriza = ({ location }) => {

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  return (
    <div>
       <BannerCookies />
      <Header
        path="/casos-de-exito/"
        location={location}
        windowsWidth={windowsWidth}
      />
      <section className="container">
        <IntroPageCustomer data={data.intro} location={location} />
        <DescriptionCustomer data={data.description} location={location} />
        <ModulesCustomer
          data={data.cards}
          datatwo={data.descriptiontwo}
          location={location}
        />
         <Footer data={dataOne.footer} location={location} />
        <FooterMobile data={dataOne.footer} location={location} />
      </section>
    </div>
  )
}

export default StructureEriza
