import React from "react"
import FotoMtg from "@components/pageCasosdeExito/image/imgPhotoEmpresa/mtg/FotoMtg.png"

//logo de empresas clientes
import Mtg from "@components/pageCasosdeExito/image/logoscustomer/Mtg.svg"

//iconos de modulos
import integracion from "@components/pageCasosdeExito/image/iconcards/integraciones.svg"
import crm from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"
import prediccion from "@components/pageCasosdeExito/image/iconcards/prediccion.svg"

const textintro = (
  <span className="">
    <span className="text-underlined-rimac">
      “MTG Perú administra y gestiona sus carteras de cobranzas y televentas en
      <span className="strong-intro-casos">
        <span className="underlined-rimac"> Beex</span>
      </span>{" "}
      mejorando su contactabilidad con nuestros
      <span className="strong-intro-casos">
        <span className="underlined-rimac"> marcadores predictivos</span> y
        administrando su negocio en la suite CRM.”
      </span>
    </span>
    <br />
    <span className="title-mtg">
      “MTG Perú administra y gestiona sus carteras de cobranzas y televentas en
      <span className="strong-intro-casos"> Beex</span> mejorando su
      contactabilidad con nuestros
      <span className="strong-intro-casos">
        {" "}
        marcadores predictivos y administrando su negocio en la suite CRM.”
      </span>
    </span>
  </span>
)

const text = (
  <span>
    MTG Perú es un Contact Center de capitales peruanos que tiene 13 años en el
    mercado, brindando servicios de atención al cliente, cobranzas y ventas;
    avalados con la norma internacional de calidad ISO 9001.
    <br />
    <br />
    Beex administra eficientemente las carteras de cobranzas y televentas
    de MTG, aplicando procesos que automatizan su operación, ya que gestionan
    productos de consumo masivo.
    <br />
    <br />
    Con Beex, aplicamos los siguientes procesos que potencian su gestión
    diaria:
  </span>
)

const texttwo = (
  <span>
    “Venimos trabajando con MTG Perú más de 3 años, en los que ha sido un gusto
    apoyarlos en lograr sus objetivos gracias a las funciones de Beex, con sus
    características de CRM y marcaciones automáticas”
  </span>
)

const data = {
  intro: {
    image: Mtg,
    textbody: textintro,
    classlogo: "customerMtg",
  },
  description: {
    textbody: text,
    photo: FotoMtg,
    class:"section-description"
  },
  cards: [
    {
      title: "Predicción",
      img: prediccion,
      info:
        "Marcaciones predictivas basadas en Machine Learning, que mejoran la contactabilidad con sus clientes, llegando a ellos de manera eficiente y en su mejor horario.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Gestión blending para su operación, reduciendo los tiempos muertos de sus teleoperadores y enrutando eficientemente las llamadas entrantes en base a su disponibilidad.",
    },
    {
      title: "Integraciones",
      img: integracion,
      info:
        "Implementación de procesos automáticos, como reportes, envíos de información, data lakes compartidos, integración mediante APIs y más.",
    },
    {
      title: "CRM",
      img: crm,
      info:
        "CRM para gestión de clientes de sus negocios, ya sean de cobranzas y sus diversos tramos, o, televentas con sus diferentes campañas.",
    },
  ],
  descriptiontwo: {
    textbody: texttwo,
  },
}

export default data
